import React from "react";
import FbIcon from "../fb-icon.svg";
import EmailIcon from "../mail-icon.svg";

function Footer() {
  return (
    <div id="footer" className="d-flex flex-column align-items-center">
      <div id="rights">Restaurante Típico &#169; 2020</div>
      <div id="icons" className="d-flex justify-content-around">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/Restaurante-Tipico-789709931072007"
        >
          <img className="icon" src={FbIcon} alt="Facebook Link" />
        </a>
        <a href="mailto:tascatipica@hotmail.com">
          <img className="icon" src={EmailIcon} alt="Email" />
        </a>
      </div>
      <span id="author">
        By{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/jo%C3%A3o-rafael-varela-503517187/"
          id="author-name"
        >
          João Varela
        </a>
      </span>
    </div>
  );
}

export default Footer;
