import React from "react";
import Francesinha from "../../francesinha.png";
import Lombinhos from "../../lombinhos.png";
import Posta from "../../posta.png";
import Polvo from "../../polvo.png";

function Main() {
  return (
    <div className="section-container">
      <h1 className="title">Especialidades</h1>
      <ul id="specialities" className="flex-container wrap">
        <li className="d-flex flex-column align-items-center speciality">
          <img
            className="speciality-image"
            src={Francesinha}
            alt="Francesinha"
          />
          <div className="speciality-name">Francesinha</div>
          <div className="speciality-description">
            A típica sandes portuguesa, acompanhada com batata frita{" "}
          </div>
          {/* <div className="speciality-price">8.5 €</div> */}
        </li>
        <li className="d-flex flex-column align-items-center speciality">
          <img className="speciality-image" src={Posta} alt="Francesinha" />
          <div className="speciality-name">Posta à Típico</div>
          <div className="speciality-description">
            Posta de vitela acompanhada de salada e batata frita{" "}
          </div>
          {/* <div className="speciality-price">8.5 €</div> */}
        </li>{" "}
        <li className="d-flex flex-column align-items-center speciality">
          <img className="speciality-image" src={Lombinhos} alt="Francesinha" />
          <div className="speciality-name">Medalhões</div>
          <div className="speciality-description">
            Medalhões de vitela acompanhados de salada e batata frita{" "}
          </div>
          {/* <div className="speciality-price">8.5 €</div> */}
        </li>{" "}
        <li className="d-flex flex-column align-items-center speciality">
          <img className="speciality-image" src={Polvo} alt="Francesinha" />
          <div className="speciality-name">Polvo Grelhado</div>
          <div className="speciality-description">
            Polvo grelhado acompanhado de batata à murro e legumes{" "}
          </div>
          {/* <div className="speciality-price">8.5 €</div> */}
        </li>{" "}
      </ul>
    </div>
  );
}

export default Main;
