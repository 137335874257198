import React from "react";

export default function Entrees() {
  return (
    <div id="desserts">
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Pão</div>
          {/* <div className="menu-item-price ml-auto">1,2 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Azeitonas</div>
          {/* <div className="menu-item-price ml-auto">1,5 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Prato de Presunto</div>
          {/* <div className="menu-item-price ml-auto">5,0 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Prato de Queijo</div>
          {/* <div className="menu-item-price ml-auto">5,5 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Alheira Grelhada</div>
          {/* <div className="menu-item-price ml-auto">4,0 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Alheira Grelhada</div>
          {/* <div className="menu-item-price ml-auto">4,5 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Linguiça Assada</div>
          {/* <div className="menu-item-price ml-auto">6,0 €</div> */}
        </div>
      </div>
    </div>
  );
}
