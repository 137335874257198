import React, { Component } from "react";
import Chef from "../../chef.svg";
import Entrees from "./MenuSections/Entrees";
import MainDishes from "./MenuSections/MainDishes";
import Desserts from "./MenuSections/Desserts";
import Drinks from "./MenuSections/Drinks";

export class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: "main",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(section, event) {
    console.log(event.target);

    [...document.getElementsByClassName("menu-section")].forEach((element) => {
      element.className = "menu-section";
    });
    event.target.className += " selected";
    this.setState((state) => ({
      section,
    }));
  }

  renderMenuSection() {
    switch (this.state.section) {
      case "entrees":
        return <Entrees />;
      case "main":
        return <MainDishes />;
      case "desserts":
        return <Desserts />;
      case "drinks":
        return <Drinks />;
      default:
        return null;
    }
  }

  render() {
    return (
      <div ref={this.props.refProp} className="section-container">
        <h1 className="title">Ementa</h1>
        <ul
          id="menu-sections"
          className="d-flex justify-content-center mt-5 pl-0"
        >
          <img id="chef-image" src={Chef} alt="Chef" />
          <li
            className="menu-section"
            onClick={(e) => this.handleClick("entrees", e)}
          >
            Entradas
          </li>
          <li
            className="menu-section selected"
            onClick={(e) => this.handleClick("main", e)}
          >
            Pratos
          </li>
          <li
            className="menu-section"
            onClick={(e) => this.handleClick("desserts", e)}
          >
            Sobremesas
          </li>
          <li
            className="menu-section"
            onClick={(e) => this.handleClick("drinks", e)}
          >
            Bebidas
          </li>
        </ul>
        {this.renderMenuSection()}
      </div>
    );
  }
}

export default Menu;
