import React from "react";
import Navbar from "../Navbar";
import { Jumbotron, Container } from "reactstrap";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function Main(props) {
  return (
    <span id="main-section">
      <Navbar
        menuRef={props.menuRef}
        aboutRef={props.aboutRef}
        contactsRef={props.contactsRef}
      />
      <Container>
        <Jumbotron>
          <h1 id="restaurant-name" className="title">
            Restaurante Típico
          </h1>
          <p id="restaurant-slogan">
            Comida tradicional portuguesa que fala por si.
          </p>
          <p className="lead">
            <button
              onClick={() => scrollToRef(props.menuRef)}
              className="btn"
              color="primary"
            >
              Experimenta!
            </button>
          </p>
        </Jumbotron>
        <div id="main-image-container">
          <div id="main-image" />
        </div>
      </Container>
    </span>
  );
}

export default Main;
