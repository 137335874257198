import React, { Component } from "react";
import { FormGroup, Row, Col } from "reactstrap";

export class EmailForm extends Component {
  state = {
    senderName: "",
    senderEmail: "",
    message: "",
    mailto: "mailto:tascatipica@hotmail.com?subject=Restaurante Típico Website",
  };

  onSubmit = (e) => {
    e.preventDefault();

    console.log("Message: " + this.state.message);
    // send email
    this.setState({
      senderName: "",
      senderEmail: "",
      message: "",
    });
  };

  //works for any changing input value
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      mailto:
        "mailto:tascatipica@hotmail.com?subject=Restaurante Típico Website&body=" +
        this.state.message +
        "%0D%0A %0D%0A" +
        this.state.senderName,
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    /* EmailJS not working, at the moment :( */

    // this.sendFeedback(
    //   "template_3ZUwsN94",
    //   this.state.senderName,
    //   this.state.senderEmail,
    //   this.state.message
    // );
  }

  sendFeedback(templateId, senderName, senderEmail, message) {
    window.emailjs
      .send(
        "mailgun", // default email provider in your EmailJS account
        templateId,
        {
          senderName,
          senderEmail,
          message,
        },
        "user_DGGz1ylUH1QVgUb08LudW"
      )
      .then((res) => {
        console.log("Sent Email");
        this.setState({ formEmailSent: true });
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => console.error("Failed to send feedback. Error: ", err));
  }

  render() {
    return (
      <form className="feedback-form">
        <Row>
          <Col xs="12" sm="6">
            <FormGroup className="">
              <p>Nome</p>
              <input
                className="text-input"
                id="senderName"
                name="senderName"
                onChange={this.onChange}
                placeholder=""
                required
                value={this.state.senderName}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup className="">
              <p>Email</p>
              <input
                className="text-input"
                id="senderEmail"
                name="senderEmail"
                onChange={this.onChange}
                placeholder=""
                required
                value={this.state.senderEmail}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className="mt-3">
          <p>Mensagem</p>
          <textarea
            className="text-input"
            id="message"
            name="message"
            onChange={this.onChange}
            placeholder=""
            required
            value={this.state.message}
          />
        </FormGroup>
        <div className="btn-group">
          {/* <input type="submit" value="submit" className="btn btn--submit" /> */}
          <a href={this.state.mailto} className="btn btn--submit">
            Enviar
          </a>
        </div>
      </form>
    );
  }
}

export default EmailForm;
