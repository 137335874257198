import React, { useState } from "react";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";

import logo from "../logo.svg";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const NavigationBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar id="navbar" light expand="md">
        <Container>
          <NavbarBrand href="/">
            <img src={logo} alt="Restaurante Típico Logo" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav id="nav-items" className="ml-auto" navbar>
              <NavItem onClick={() => scrollToRef(props.menuRef)}>
                <NavLink href="#">Ementa</NavLink>
              </NavItem>
              <NavItem onClick={() => scrollToRef(props.aboutRef)}>
                <NavLink href="#">Sobre Nós</NavLink>
              </NavItem>
              <NavItem onClick={() => scrollToRef(props.contactsRef)}>
                <NavLink href="#">Contactos</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
