import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faClock,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import EmailForm from "../EmailForm";

mapboxgl.accessToken =
  "pk.eyJ1IjoicmV2dWoiLCJhIjoiY2tjcnl3OTUyMGlteDJ4anZtbHo2Zm1tdiJ9.mPgdnWGr2WtlE_fJ0AeiwQ";

export class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: -7.5789004,
      lat: 41.2646139,
      zoom: 17,
    };
  }

  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    new mapboxgl.Marker({ color: "#ffb800" })
      .setLngLat([-7.5789004, 41.2646139])
      .addTo(map);
  }

  render() {
    return (
      <div ref={this.props.refProp} className="section-container">
        <h1 className="title">Contactos</h1>
        <div
          id="contacts-section"
          className="d-flex align-items-start justify-content-between"
        >
          <div id="map-container">
            <div
              ref={(el) => (this.mapContainer = el)}
              className="mapContainer"
            />
            <div id="restaurant-info">
              <div id="address">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                R. do Bouço 2, 5060-327 Sabrosa
              </div>
              <div id="schedule">
                <FontAwesomeIcon icon={faClock} />
                10:30 - 22:00 * {/*Check if Open*/}
                <span id="schedule-exception">* Exceto Domingos à noite</span>
              </div>
              <div id="phone">
                <FontAwesomeIcon icon={faPhone} />
                259 106 478
              </div>
            </div>
          </div>
          <div id="email-form-container">
            <EmailForm />
          </div>
        </div>
      </div>
    );
  }
}

export default Contacts;
