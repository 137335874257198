import React from "react";

export default function Drinks() {
  return (
    <div id="desserts">
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Vinhos da Região</div>
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Água</div>
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Refregirantes</div>
        </div>
      </div>
    </div>
  );
}
