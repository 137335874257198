import React from "react";

export default function MainDishes() {
  return (
    <div id="main-dishes">
      <h4 className="main-dish-section">Carnes</h4>
      <div id="desserts">
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Posta à Típico</div>
            {/* <div className="menu-item-price ml-auto">18,0 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Medalhões de Vitela</div>
            {/* <div className="menu-item-price ml-auto">12,5 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Costeleta de Novilho</div>
            {/* <div className="menu-item-price ml-auto">11,0 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Bifinhos com Cogumelhos</div>
            {/* <div className="menu-item-price ml-auto">9,5 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Prego no Prato</div>
            {/* <div className="menu-item-price ml-auto">8,5 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Francesinha</div>
            {/* <div className="menu-item-price ml-auto">7,0 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Secretos de Porco</div>
            {/* <div className="menu-item-price ml-auto">8,5 €</div> */}
          </div>
        </div>
      </div>
      <h4 className="main-dish-section">Peixes</h4>
      <div id="desserts">
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Polvo Grelhado</div>
            {/* <div className="menu-item-price ml-auto">24,0 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Bacalhau Grelhado</div>
            {/* <div className="menu-item-price ml-auto">18,0 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Bacalhau à Típico</div>
            {/* <div className="menu-item-price ml-auto">18,0 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Peixe Grelhado</div>
            {/* <div className="menu-item-price ml-auto">9,0 €</div> */}
          </div>
        </div>
      </div>
      <h4 className="main-dish-section">Saladas</h4>
      <div id="desserts">
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Mista</div>
            {/* <div className="menu-item-price ml-auto">5,0 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Transmontana</div>
            {/* <div className="menu-item-price ml-auto">8,5 €</div> */}
          </div>
        </div>
      </div>
      <h4 className="main-dish-section">Omeletes</h4>
      <div id="desserts">
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Mista</div>
            {/* <div className="menu-item-price ml-auto">5,5 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Vegetariana</div>
            {/* <div className="menu-item-price ml-auto">5,5 €</div> */}
          </div>
        </div>
        <div className="dessert-container">
          <div className="dessert d-flex">
            <div className="menu-item-name">Atum</div>
            {/* <div className="menu-item-price ml-auto">7,0 €</div> */}
          </div>
        </div>
      </div>
      <p id="main-dishes-note">
        <strong>Nota</strong>: Nem todos os items do menu podem encontrar-se
        sempre em disponibilidade. Para além disso, poderão também existir{" "}
        <strong>Pratos do Dia</strong> como, por exemplo, vitela, cabrito ou
        leitão assados no forno, entre outros.
      </p>
    </div>
  );
}
