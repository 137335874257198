import React, { useRef } from "react";
import Main from "./Components/Sections/Main";
import Specialities from "./Components/Sections/Specialities";
import Menu from "./Components/Sections/Menu";
import About from "./Components/Sections/About";
import Contacts from "./Components/Sections/Contacts";
import Footer from "./Components/Footer";
import "./App.css";
import { Container } from "reactstrap";

function App() {
  const menuRef = useRef(null);
  const aboutRef = useRef(null);
  const contactsRef = useRef(null);

  return (
    <div className="App">
      <Main menuRef={menuRef} aboutRef={aboutRef} contactsRef={contactsRef} />
      <Container id="page-content">
        <Specialities />
        <Menu refProp={menuRef} />
        <About refProp={aboutRef} />
        <Contacts refProp={contactsRef} />
      </Container>
      <Footer />
    </div>
  );
}

export default App;
