import React from "react";
import About from "../../restaurante-tipico-1.jpg";

function Main(props) {
  return (
    <div ref={props.refProp} className="section-container">
      <h1 className="title">Sobre Nós</h1>
      <div id="about-section" className="d-flex align-items-start">
        <img
          className="about-image"
          src={About}
          alt="Sérgio Lapa e Dona Fátima"
        />
        <div id="about-text">
          <p>
            Criado em 2013 pela <strong>Dona Fátima</strong> e o seu filho{" "}
            <strong>Sérgio Lapa</strong>, o Restaurante Típico nasceu para
            alargar os horizontes do seu restaurante “irmão” (Tasca Típica)
            satisfazendo as necessidades e apetites de ainda mais Sabrosenses ou
            visitantes da região.
          </p>
          <p>
            O nosso objetivo fulcral passa por garantir pratos portugueses de
            qualidade, aos nossos clientes, de uma forma que seja acessível a
            todos, para que possamos mostrar a toda a gente aquilo que é uma das
            melhores coisas que a nossa região tem para oferecer. Tentamos
            sempre que o cliente saia do restaurante com um sorriso na cara e
            vontade de voltar e, para isso, damos extrema importância a um
            serviço cordial conferido pela nossa equipa de profissionais.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Main;
