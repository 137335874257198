import React from "react";

export default function Desserts() {
  return (
    <div id="desserts">
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Doce da Casa</div>
          {/* <div className="menu-item-price ml-auto">2,5 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Mousse de Chocolate</div>
          {/* <div className="menu-item-price ml-auto">2,5 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Panacota</div>
          {/* <div className="menu-item-price ml-auto">2,5 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Delícia de Bolacha</div>
          {/* <div className="menu-item-price ml-auto">2,5 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Mousse de Ananás</div>
          {/* <div className="menu-item-price ml-auto">2,5 €</div> */}
        </div>
      </div>
      <div className="dessert-container">
        <div className="dessert d-flex">
          <div className="menu-item-name">Salada de Fruta</div>
          {/* <div className="menu-item-price ml-auto">2,5 €</div> */}
        </div>
      </div>
    </div>
  );
}
